
import { IonContent, IonInput, IonTextarea, modalController } from "@ionic/vue";
import { defineComponent } from "vue";
import { useStore } from "../store";
import { DatePicker } from "v-calendar";

export default defineComponent({
  name: "CreateMyEvent",
  components: {
    IonContent,
    IonInput,
    IonTextarea,
    DatePicker,
  },
  data: () => {
    return {
      store: useStore(),
      loading: false,
      errors: {},
      name: "",
      date: "",
      description: "",
      modelConfig: {
        type: "string",
        mask: "YYYY-MM-DD",
      },
    };
  },
  methods: {
    async createMyEvent() {
      this.loading = true;
      this.errors = {};
      try {
        const data = {
          name: this.name,
          date: this.date.split("T")[0],
          description: this.description,
        };
        const res = await this.store.dispatch("createMyEvent", data);
        if (res == 200) {
          modalController.dismiss();
        }
      } catch (e) {
        // console.log("catch error on create my event");
        // console.log(e.response);
        this.errors = e.response.data;
      }
      this.loading = false;
    },
  },
});
