
import { defineComponent } from "vue";
import { modalController } from "@ionic/vue";
import CreateMyEvent from "../components/CreateMyEvent.vue";
import { useStore } from "../store";
import { DateTime } from "luxon";

export default defineComponent({
  name: "MyCalendar",
  components: {},
  data: () => {
    return {
      store: useStore(),
    };
  },
  computed: {
    events(): [] {
      return this.store.state.user.events;
    },
  },
  methods: {
    async deleteEvent(id: any) {
      if (confirm("Are you sure you want to delete this event?")) {
        try {
          const data = {
            eventId: id,
          };
          await this.store.dispatch("deleteMyEvent", data);
        } catch (e) {
          console.log("catch error on delete my event");
          console.log(e.response);
        }
      }
    },
    async openModal() {
      const modal = await modalController.create({
        component: CreateMyEvent,
        cssClass: "",
        componentProps: {
          title: "Add To Party",
        },
      });
      return modal.present();
    },
    formatDate(date: string): any {
      // 2021-08-28T14:30:52.688+01:00
      return DateTime.fromISO(date).toFormat("dd LLL yyyy");
    },
  },
});
